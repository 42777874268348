import { useState, useEffect } from 'react';

function useCardRotate(ref) {
  const [cardStyle, setCardStyle] = useState({
    transform: '',
    boxShadow: '',
  });

  useEffect(() => {
    const cardel = ref.current;

    const handleMouseMove = (e) => {
      const rect = cardel.getBoundingClientRect();
      const x = rect.x;
      const y = rect.y;
      const width = rect.width;
      const height = rect.height;

      const left = e.clientX - x;
      const top = e.clientY - y;

      const centerX = left - width / 2;
      const centerY = top - height / 2;
      const d = Math.sqrt(centerX ** 2 + centerY ** 2);

      setCardStyle({
        transform: `rotate3d(${centerY / 100}, ${-centerX / 100}, 0, ${d / 8}deg)`,
        boxShadow: `${-centerX / 50}px ${-centerY / 50}px 10px rgba(0, 0, 0, 0.2)`
      });
    };

    const handleMouseEnter = () => {
      cardel.addEventListener('mousemove', handleMouseMove);
    }

    const handleMouseLeave = () => {
      setCardStyle({transform: '', boxShadow: ''});
      cardel.removeEventListener('mousemove', handleMouseMove);
    }

    cardel.addEventListener('mouseenter', handleMouseEnter);
    cardel.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      cardel.removeEventListener('mouseenter', handleMouseEnter);
      cardel.removeEventListener('mouseleave', handleMouseLeave);
      cardel.removeEventListener('mousemove', handleMouseMove);
    };
  }, [ref]);

  return cardStyle;
}

export default useCardRotate;
